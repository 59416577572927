import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

// Register the Chart.js components
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const TradingViewWidget = () => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation(); // Initialize translation hook

  const commodities = ["WTI", "NATURAL_GAS", "BRENT", "ALUMINUM"]; // Add more commodities here
  const apiKey = "demo"; // Replace with your Alpha Vantage API key

  useEffect(() => {
    const fetchCommodityData = async (commodity) => {
      const url = `https://www.alphavantage.co/query?function=${commodity}&interval=monthly&apikey=${apiKey}`;
      const response = await axios.get(url);
      const { data } = response.data;
      if (!data) throw new Error(`No data for ${commodity}`);

      // Filter for the last 12 months
      const today = new Date();
      const last12Months = [...Array(12)].map((_, i) => {
        const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
        return date.toISOString().split("T")[0].slice(0, 7); // Format: YYYY-MM
      });

      return data
        .filter((item) => last12Months.includes(item.date.slice(0, 7)))
        .map((item) => ({ date: item.date, value: parseFloat(item.value) }));
    };

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const commodityData = await Promise.all(
          commodities.map((commodity) => fetchCommodityData(commodity))
        );

        // Extract unique dates (last 12 months)
        const dates = Array.from(
          new Set(commodityData.flat().map((item) => item.date))
        ).sort();

        // Prepare datasets for each commodity
        const datasets = commodityData.map((data, index) => ({
          label: commodities[index],
          data: dates.map(
            (date) => data.find((item) => item.date === date)?.value || null
          ),
          borderColor: `hsl(${index * 120}, 70%, 50%)`, // Unique colors for each commodity
          fill: false,
        }));

        setChartData({
          labels: dates,
          datasets,
        });
        console.log("yo yo", chartData, dates);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <section className="section-box">
      <div
        style={{
          width: "100%",
          height: "400px",
          margin: "20px auto",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <h3
          style={{
            margin: "0 0 10px",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333",
            textAlign: "center",
          }}
        >
          {t("commodityPrices")}
        </h3>
        <Line
          data={chartData}
          options={{
            maintainAspectRatio: false, // Ensure fixed dimensions
            scales: {
              x: {
                title: { display: true, text: "Date" },
                ticks: {
                  color: "#666",
                  font: {
                    size: 12,
                  },
                },
              },
              y: {
                beginAtZero: true,
                title: { display: true, text: "Price" },
                ticks: {
                  color: "#666",
                  font: {
                    size: 12,
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: true,
                position: "top",
                labels: {
                  color: "#333",
                  font: {
                    size: 14,
                    weight: "bold",
                  },
                },
              },
            },
          }}
          height={400} // Fixed height
          width={800} // Fixed width
        />
      </div>
    </section>
  );
};

export default TradingViewWidget;
